import Auth from '@aws-amplify/auth';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Routings } from '@/common/routings';
import * as buyer from '@/state/modules/buyer';

import {
  Area,
  ButtonArea,
  StyledErrorMessage,
  LoginButton,
  MailField,
  MailTextField,
  PasswordField,
  PasswordTextCustomField,
  ResetPasswordArea,
  SignUpButton,
  StyledLink,
  SubTitle,
  SubTitleDecoration,
  Wrapper,
} from './StyledComponents';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Main } from '@/components/layout/common';
import PageTitle from '@/components/atoms/PageTitle';
import { FormState, schema } from './state';

const Login = () => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormState>({
    mode: 'all',
    resolver: yupResolver(schema),
  });
  const hasError = Object.keys(errors).length > 0;
  const [failed, setFailed] = useState(false);
  const savePath = useSelector(buyer.selectors.savePath);

  useEffect(() => {
    return () => {
      dispatch(buyer.operations.savePath({}));
    };
  }, []);

  const onSubmit = async (values: FormState) => {
    try {
      await Auth.signIn(values.email, values.password);
      const { redirectDist } = savePath;
      if (redirectDist) {
        // 未ログイン状態で売出し中物件詳細画面、もしくは会員情報詳細画面にURL指定で直アクセスした場合は、ログイン後に表示する
        navigate(redirectDist);
      } else {
        navigate(Routings.buyerTop.location);
      }
    } catch (err) {
      // NotAuthorizedException
      console.debug(err);
      setFailed(true);
    }
  };

  return (
    <Main>
      <PageTitle>ログイン</PageTitle>
      <Wrapper>
        <Area>
          <form onSubmit={handleSubmit(onSubmit)} id="loginForm">
            <MailField>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <MailTextField id="email" type="email" placeholder="メールアドレス" {...field} />
                )}
              />
              {errors?.email && <StyledErrorMessage>{errors.email?.message}</StyledErrorMessage>}
            </MailField>
            <PasswordField id="test">
              <Controller
                name="password"
                control={control}
                render={({ field }) => <PasswordTextCustomField placeholder="******************" {...field} />}
              />
              {errors?.password && <StyledErrorMessage>{errors.password?.message}</StyledErrorMessage>}
              {failed && <StyledErrorMessage>メールアドレスまたはパスワードが正しくありません</StyledErrorMessage>}
            </PasswordField>
          </form>
          <ResetPasswordArea>
            ログインできない方は<StyledLink to={Routings.buyerResetPassword.location}>こちら</StyledLink>
          </ResetPasswordArea>
          <ButtonArea>
            <LoginButton onClick={handleSubmit(onSubmit)} disabled={hasError}>
              ログイン
            </LoginButton>
          </ButtonArea>
        </Area>
        <SubTitle>
          <SubTitleDecoration>はじめてご利用の方</SubTitleDecoration>
        </SubTitle>
        <ButtonArea>
          <SignUpButton onClick={() => navigate(Routings.accountRegistration.location)}>
            新規会員登録はこちら
          </SignUpButton>
        </ButtonArea>
      </Wrapper>
    </Main>
  );
};

export default Login;
