import { Link } from 'gatsby';
import styled from 'styled-components';
import { Breakpoints } from '@/constants/constants';
import Button from '@/components/atoms/Button';
import TextField from '@/components/atoms/TextField';
import PasswordTextField from '@/components/atoms/PasswordTextField';
import theme from '@/styles/theme';
import ErrorMessage from '@/components/atoms/ErrorMessage';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 61px 17px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 61px 17px;
  }
`;
export const Area = styled.div`
  width: 100%;
  max-width: 1064px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 40px;
  background: #f7f7f7;
  padding: 40px 20px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 30px 20px;
  }
`;
export const SubTitle = styled.h2`
  width: 100%;
  text-align: center;
  margin-bottom: 21px;
`;
export const SubTitleDecoration = styled.span`
  font: ${theme.fonts.bold_22_33};
  color: #4c586f;
  opacity: 1;
`;

export const ResetPasswordArea = styled.div`
  font: ${theme.fonts.normal_12_22};
  text-align: center;
  color: #222222;
  margin-bottom: 21px;
`;
export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #d1a827;
`;

export const ButtonArea = styled.div`
  text-align: center;
  width: 100%;
`;
export const LoginButton = styled(Button)`
  width: 299px;
  height: 64px;
  border-radius: 4px;
  font: ${theme.fonts.bold_22_33};
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

export const SignUpButton = styled(Button)`
  width: 299px;
  height: 64px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  color: #4c586f;
  background: #ffffff;
  font: ${theme.fonts.bold_16_32};
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;
export const MailField = styled.div`
  width: 408px;
  margin: 0 auto;
  margin-bottom: 20px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

export const MailTextField = styled(TextField)`
  width: 100%;
  height: 40px;
  ::placeholder {
    font: ${theme.fonts.normal_16_32};
    color: #cccccc;
  }
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  width: 100%;
  text-align: left;
`;

export const PasswordField = styled.div`
  width: 408px;
  margin: 0 auto;
  margin-bottom: 15px;
  div {
    height: 40px;
    div {
      height: 38px;
    }
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

export const PasswordTextCustomField = styled(PasswordTextField)`
  ::placeholder {
    font: ${theme.fonts.bold_16_32};
    color: #cccccc;
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;
