import React from 'react';
import Login from '@/components/organisms/Buyer/Login';
import withInitializeApp from '@/utils/hoc/withInitializeApp';
import BuyerHeader from '@/components/atoms/Header/BuyerHeader';
import BuyerFooter from '@/components/atoms/Footer/BuyerFooter';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const BuyerLoginPage = () => {
  return (
    <>
      <Wrapper>
        <BuyerHeader displayPattern="inquiry" />
        <Login />
        <BuyerFooter />
      </Wrapper>
    </>
  );
};

export default withInitializeApp(BuyerLoginPage);
