import * as yup from 'yup';
import { YupErrorMessage } from '@/constants/messages';
import { multiByteCharacterValidator } from '@/utils/multiByteValidator';

export const schema = yup.object({
  email: yup
    .string()
    .trim()
    .test(
      'multi_byte_character_not_accepted',
      YupErrorMessage.string.multi_byte_character_not_accepted,
      multiByteCharacterValidator
    )
    .required(YupErrorMessage.required)
    .max(254, YupErrorMessage.string.max)
    .email(YupErrorMessage.string.email)
    .label('メールアドレス'),
  password: yup
    .string()
    .trim()
    .required(YupErrorMessage.required)
    .max(99, YupErrorMessage.string.max)
    .label('パスワード'),
});
export type FormState = yup.InferType<typeof schema>;
